<script setup></script>

<template>
  <div id="dkitchen">
    <button-router></button-router>
    
    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
        <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
#dkitchen {
  /* height: 100%; */
}
</style>
